<template>
  <div class="modal is-active">
    <div class="modal-card-body has-background-white-ter has-text-grey-dark">
      <div class="container">
        <div class="content">
          <p class="title is-4 has-text-grey-dark" v-if="isUser">Products</p>
          <div class="columns is-multiline">
            <div class="column is-12" v-if="hasMulti">
              <router-link
                @click.native="track('go_to_multi')"
                class="has-text-grey-dark"
                :class="{ [classActive]: is('multi-dash') }"
                :to="{ name: 'multi-dash' }"
              >
                Multi
              </router-link>
            </div>
            <div class="column is-12" v-if="hasValuationExplorer">
              <router-link
                @click.native="track('go_to_multi')"
                class="has-text-grey-dark"
                :class="{ [classActive]: is('explorer') }"
                :to="{ name: 'explorer' }"
                >Valuation Explorer</router-link
              >
            </div>
            <div class="column is-12" v-if="hasStockview">
              <router-link
                @click.native="track('go_to_stockview_dash')"
                class="has-text-grey-dark"
                :class="{ [classActive]: is('stockview') }"
                :to="{ name: 'stockview' }"
              >
                Stockview - Dashboard
              </router-link>
            </div>
            <div class="column is-12" v-if="hasStockview">
              <router-link
                @click.native="track('go_to_stockview_stock')"
                class="has-text-grey-dark"
                :class="{ [classActive]: is('stockview-stock') }"
                :to="{ name: 'stockview-stock' }"
              >
                Stockview - Stock
              </router-link>
            </div>
            <div class="column is-12" v-else-if="isGuest">
              <router-link
                class="has-text-grey-dark"
                :to="{ name: 'auth-login' }"
                :disabled="is('auth-login')"
              >
                Log in
              </router-link>
            </div>
          </div>
        </div>
        <p class="title is-4 has-text-grey-dark" v-if="isUser">Account</p>
        <div class="columns is-multiline" v-if="isUser">
          <div class="column is-12">
            <router-link
              class="has-text-grey-dark"
              @click.native="track('go_to_account_details')"
              :to="{ name: 'account-details' }"
              :class="{ [classActive]: is('account-details') }"
            >
              <span>Edit profile</span>
            </router-link>
          </div>
          <div class="column is-12">
            <router-link
              class="has-text-grey-dark"
              @click.native="track('go_to_account_user_activity')"
              :to="{ name: 'account-activity-user' }"
              :class="{ [classActive]: is('account-activity-user') }"
            >
              <span>My activity</span>
            </router-link>
          </div>
          <div class="column is-12">
            <router-link
              class="has-text-grey-dark"
              @click.native="track('go_to_account_branch_activity')"
              :to="{ name: 'account-activity-branch' }"
              :class="{ [classActive]: is('account-activity-branch') }"
            >
              <span>Branch activity</span>
            </router-link>
          </div>
          <div
            class="column is-12"
            v-if="organisation && isBranchAdmin && branchHasAdmin"
          >
            <router-link
              class="has-text-grey-dark"
              @click.native="track('go_to_account_org_activity')"
              :to="{ name: 'account-activity-org' }"
              :class="{ [classActive]: is('account-activity-org') }"
            >
              <span>Organisation activity</span>
            </router-link>
          </div>
          <div class="column is-12" v-if="isBranchAdmin && branchHasAdmin">
            <router-link
              class="has-text-grey-dark"
              @click.native="track('go_to_account_branch_admin')"
              :to="{ name: 'account-branch-admin' }"
              :class="{ [classActive]: is('account-branch-admin') }"
            >
              <span>Manage branches</span>
            </router-link>
          </div>
          <div class="column is-12">
            <a
              class="has-text-grey-dark"
              @click="$modal.open('account/AccountSwitch')"
            >
              <span>Switch branch</span>
            </a>
          </div>
          <div class="column is-12">
            <a class="has-text-grey-dark" @click="logOut">
              <span>Log out</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noScroll from 'no-scroll'
import { mapGetters, mapMutations } from 'vuex'

import { logOut } from 'modules/auth/services'
export default {
  name: 'MobileNav',
  methods: {
    ...mapMutations('overlay', ['toggle']),
    is(name) {
      return this.$route.name === name
    },
    track(action) {
      this.$mxp.track(`mobile_nav_${action}`)
    },
    logOut() {
      this.track('logout')
      logOut()
    }
  },
  mounted: () => noScroll.on(),
  destroyed: () => noScroll.off(),
  watch: {
    $route: 'toggle',
    modalIsActive() {
      return noScroll.on()
    }
  },
  computed: {
    classActive: () => 'is-active',
    ...mapGetters('auth', [
      'isUser',
      'isGuest',
      'hasMulti',
      'hasValuationExplorer',
      'hasStockview',
      'isBranchAdmin',
      'branchHasAdmin',
      'organisation',
      'isIntegration'
    ])
  }
}
</script>

<style lang="sass" scoped>
.modal
  margin-top: $navbar-height
  border-radius: 0
  &-card-body
    width: 100vw
.container
  align-items: center
</style>
